<template>
	<div class="auction-detail">
		<div class="top">
			<my-nft-list-nav :index="nav_index" :list="nav_list" @click="navTap"/>
		</div>
		<div class="main">
			<div class="main-top">
				<div class="main-top_left">
					<img class="main-top_bg" src="@/assets/bg_revoke.svg" alt="">
					<img class="main-top_img" :src="card_img" alt="">
				</div>
				<div class="m-card_name" v-if="is_mobile">{{ card.data.zhHans }}</div>
				<div class="main-top_right">
					<div class="name" v-if="!is_mobile">{{ card.data.zhHans }}</div>
					<div class="main-top_grid">
						<div class="main-top_item">
							<span>{{ $t('LEFT TIME') }}</span>
							<span>{{ time ? time : 'ENDED' }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('CURRENT BID') }}</span>
							<span>{{ card.price }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('OWNER') }}</span>
							<span>{{ number_owner }}</span>
						</div>

						<div class="main-top_item" v-if="!is_combo">
							<span>{{ $t('MINING POWER') }}</span>
							<span>{{ card.data.mining_power }}</span>
						</div>

						<div class="main-top_item" v-if="is_combo">
							<span>{{ $t('GOD STRENGTH') }}</span>
							<span>{{ card.data.god_strength }}</span>
						</div>

						<div class="main-top_item" v-if="is_combo">
							<span>{{ $t('BONUS PROPORTION') }}</span>
							<span>{{ card.data.bonus_proportion }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('BUY OUT PRICE') }}</span>
							<span>{{ card.buyoutPrice }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('YOUR BALANCE') }}</span>
							<span>{{ parseFloat(amt_balance) }}</span>
						</div>

						<div class="main-top_item">
							<span>{{ $t('START PRICE') }}</span>
							<span>{{ card.startPrice }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="main-btn_list">
				<v-btn
					class="mr-4 mb-4"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					:disabled="card.isEnd"
					@click="dialog = true"
				>{{ $t('PLACE A BID') }}</v-btn>
				<v-btn
						v-show="is_owner"
						class="mr-4 mb-4"
						:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
						color="#EB6930"
						large
						outlined
						rounded
						:loading="settle_bid_loading"
						@click="endCard"
				>{{ $t('SETTLE BID') }}</v-btn>
				<v-btn
						class="mb-4"
						v-if="is_cancel"
						:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
						color="#EB6930"
						large
						outlined
						rounded
						:loading="cancel_loading"
						@click="cancel"
					>{{ $t('CANCEL') }}</v-btn>
			</div>
		</div>

		<div class="pc-auction_history">
			<div class="auction-history_box">
				<div class="auction-history_title">{{ $t('Bid History') }}</div>
				<table>
					<tr>
						<th></th>
						<th>{{ $t('ADDRESS') }}</th>
						<th>{{ $t('PRICE') }}</th>
					</tr>
					<tr v-for="(item, index) in bidList" :key="index">
						<td>{{ index + 1 }}</td>
						<td>{{ `${item.address.slice(0, 5)}...${item.address.substr(-4)}` }}</td>
						<td>{{ item.amount }} AMT</td>
					</tr>
					<tr v-show="bidList.length === 0">
						<td>-</td>
						<td>-</td>
						<td>-</td>
					</tr>
					<tr v-show="bidList.length === 0">
						<td colspan="3">
							<div class="auction-history_no">{{ $t('No Bid History Yet') }}</div>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<v-dialog v-model="dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('PLACE A BID') }}</span>
					<v-btn @click="dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content auction-detail_dialog">
					<div class="dialog-content">
						<div class="dialog-content_left">
							<img class="main-top_bg" src="@/assets/bg_revoke.svg" alt="">
							<img class="main-top_img" :src="card_img" alt="">
						</div>
						<div class="dialog-content_right">
							<div class="dialog-content_title">{{ card.data.zhHans }}</div>
							<div v-if="!is_mobile">
								<div class="dialog-content_item">
									<span>{{ $t('YOUR BALANCE') }}</span>
									<span>{{ parseFloat(amt_balance) }}</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('BUY OUT PRICE') }}</span>
									<span>{{ card.buyoutPrice }}</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('START PRICE') }}</span>
									<span>{{ card.startPrice }}</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('CURRENT BID') }}</span>
									<span>{{ card.price }}</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('PLACE BID RANGE') }}</span>
									<span>0</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('SERVICE FEE') }}</span>
									<span>5%</span>
								</div>

								<div class="dialog-content_item">
									<span>{{ $t('YOUR BID') }}</span>
									<input type="number" :placeholder="card.price" v-model="price">
								</div>
							</div>
						</div>
					</div>
					<div v-if="is_mobile">
						<div class="dialog-content_item">
							<span>{{ $t('YOUR BALANCE') }}</span>
							<span>{{ parseFloat(amt_balance) }}</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('BUY OUT PRICE') }}</span>
							<span>{{ card.buyoutPrice }}</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('START PRICE') }}</span>
							<span>{{ card.startPrice }}</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('CURRENT BID') }}</span>
							<span>{{ card.price }}</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('PLACE BID RANGE') }}</span>
							<span>0</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('SERVICE FEE') }}</span>
							<span>5%</span>
						</div>

						<div class="dialog-content_item">
							<span>{{ $t('YOUR BID') }}</span>
							<input type="number" :placeholder="card.price" v-model="price">
						</div>
					</div>
					<div :class="{'text-center': is_mobile}">
						<v-btn
								:style="[is_mobile ? m_btn_styles : p_btn_styles]"
								color="#EB6930"
								large
								rounded
								:loading="bid_loading"
								@click="_bid"
						>{{ mat_approve ? $t('PLACE A BID') : $t('APPROVE') }}</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { SessionStorage } from 'storage-manager-js'
import eventBus from '@/utils/eventBus'
import { getAMTAmt, bid, transactionApprove, transactionIsApprove, end, redeem, bidList } from '@/utils/cardFactory'
import Big from 'big.js'
import { mapState } from 'vuex'
import { getCardImgUrl } from '@/utils/tools'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'

export default {
	name: 'AuctionDetail',
	components: { MyNftListNav },
	data () {
		return {
			dialog: false,
			card: null,
			amt_balance: 0,
			price: '',
			bid_loading: false,
			mat_approve: false,
			date_left: 0,
			timer: null,
			time: '',
			cancel_loading: false,
			nav_list: [],
			nav_index: 0,
			mobile_btn_styles: {
				height: '34px !important',
			},
			pc_btn_styles: {
				height: '52px !important',
			},
			settle_bid_loading: false,
			m_btn_styles: {
				height: '36px !important',
				width: '148px',
			},
			p_btn_styles: {
				height: '52px !important',
				width: '237px',
			},
			bidList: [],
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		card_img () {
			return getCardImgUrl(this.card.type)
		},
		is_combo () {
			return this.card.type.split('-')[0] === '9'
		},
		number_owner () {
			return `${this.card.seller.slice(0, 4)}...${this.card.seller.substr(-4)}`
		},
		is_owner () {
			return this.ADDRESS.toUpperCase() === this.card.seller.toUpperCase()
		},
		total_cost () {
			const price = new Big(this.price || 0)
			const fee_pro = new Big(0.05)
			const fee = price.times(fee_pro)
			return price.plus(fee)
		},
		is_cancel () {
			return this.card.winner === '0x0000000000000000000000000000000000000000' && (this.ADDRESS.toUpperCase() === this.card.seller.toUpperCase())
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	async created () {
		this.card = SessionStorage.get('AUCTION_CARD')
		this.nav_list = SessionStorage.get('AUCTION_NAV')
		const { index } = this.$route.query
		if (index) {
			this.nav_index = Number(index)
		}
		this.endTime = this.card.auctionEnd * 1000
		this.initCountdown()
		if (this.ADDRESS) {
			this._amtBalance()
			this._bidList()
			this.mat_approve = await transactionIsApprove(this.ADDRESS)
		}
		eventBus.$on('connect-event', async (res) => {
			this._amtBalance()
			this._bidList()
			this.mat_approve = await transactionIsApprove(this.ADDRESS)
		})
	},
	methods: {
		async _bidList () {
			// 出价历史
			this.bidList = await bidList(this.card.index)
			console.info(this.bidList)
		},
		navTap ({ index }) {
			this.$router.push(`/app/AuctionList?index=${index}`)
		},
		countdown () {
			const moment = this.$moment
			const hours = this.prefixInteger(moment.duration(this.date_left).hours(), 2)
			const minutes = this.prefixInteger(moment.duration(this.date_left).minutes(), 2)
			const seconds = this.prefixInteger(moment.duration(this.date_left).seconds(), 2)
			return `${hours}:${minutes}:${seconds}`
		},
		prefixInteger (num, length) {
			return (Array(length).join('0') + num).slice(-length)
		},
		initCountdown () {
			const now_date = new Big(new Date().getTime())
			const auctionEnd = new Big(this.card.auctionEnd)
			const end_date = auctionEnd.times(1000)
			const date_left = end_date.minus(now_date).toNumber()
			if (now_date.toNumber() < end_date.toNumber()) {
				this._startCountdown(date_left)
			}
		},
		_startCountdown (date) {
			const date_left = new Big(date)
			this.timer = setTimeout(() => {
				this.date_left = date_left.minus(1000).toNumber()
				if (this.date_left <= 0) {
					this.time = 'Ended'
					clearTimeout(this.timer)
				} else {
					this.time = this.countdown()
					this._startCountdown(this.date_left)
				}
			}, 1000)
		},
		async _amtBalance () {
			this.amt_balance = await getAMTAmt(this.ADDRESS)
		},
		async _bid () {
			if (this.mat_approve) {
				if (this.price === '') return
				this.bid_loading = true
				try {
					const {
						index,
						tokenId,
					} = this.card
					await bid(this.ADDRESS, index, tokenId, this.price)
					this.bid_loading = false
					this.$router.back()
				} catch (err) {
					this.bid_loading = false
				}
			} else {
				this.bid_loading = true
				await transactionApprove(this.ADDRESS)
				try {
					this.mat_approve = await transactionIsApprove(this.ADDRESS)
					this.bid_loading = false
				} catch (e) {
					this.bid_loading = false
				}
			}
		},
		//
		async endCard () {
			this.settle_bid_loading = true
			try {
				const {
					liveIndex,
					tokenId,
				} = this.card
				await end(this.ADDRESS, liveIndex, tokenId)
				this.settle_bid_loading = false
				this.$router.back()
			} catch (err) {
				console.info(err)
				this.settle_bid_loading = false
			}
		},
		// 取消
		async cancel () {
			this.cancel_loading = true
			try {
				const {
					liveIndex,
				} = this.card
				await redeem(this.ADDRESS, liveIndex)
				this.cancel_loading = false
				this.$router.back()
			} catch (err) {
				this.cancel_loading = false
			}
		},
	},
	beforeDestroy () {
		if (this.timer) clearInterval(this.timer)
	},
}
</script>

<style lang="scss" scoped>
.auction-detail_dialog {
	display: flex;
	flex-direction: column;
}

.auction-detail {
	.main-top_right {
		.name {
			padding: 38px 0 40px;
			font-weight: bold;
			font-size: 36px;
			line-height: 54px;
			color: #E8E8E8;
		}
	}
	.main-top_left {
		.main-top_img {
			position: absolute;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-top_item {
		&:after {
			content: " ";
			width: 10px;
			height: 10px;
			background-color: #EB6930;
			position: absolute;
			left: 0;
			transform: rotate(45deg);
		}
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 22px;
	}
	.main {
		.main-btn_list {
			width: 100%;
			max-width: 931px;
		}
		background-color: #202831;
		display: flex;
		flex-direction: column;
		padding-bottom: 80px;
	}
}
</style>
